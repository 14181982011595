import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },

  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: spfOne,
    productName: "HP LaserJet ",
    price: "200.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "202",
    img: newArrFour,
    productName: "HP LaserJet ",
    price: "700.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "203",
    img: spfThree,
    productName: "HP inkJet Printer",
    price: "170.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "204",
    img: spfFour,
    productName: "HP inkJet Printer",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
  {
    _id: 1001,
    img: spfOne,
    productName: "HP LaserJet Enterprise P3015DN Printer (CE528A)",
    price: "200.00",
    color: "White Laserjet Printers",
    badge: true,
    des: "This Certified Refurbished product has undergone a rigorous refurbishing process to ensure it looks and works like new. Each printer has been tested for functionality, undergone basic cleaning, inspection, and repackaging. It comes with all relevant accessories and a minimum 90-day warranty for your peace of mind.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "HP OfficeJet Pro 6978 All-in-One Wireless Printer, Copier",
    price: "340.00",
    color: "Black officejet Printers",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "HP DeskJet All-in-One Wireless Color Inkjet Printer",
    price: "170",
    color: "DeskJet Printers, HP Printer, InkJet Printers",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9015e All-in-One Printer",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9015e is an advanced all-in-one printer that delivers professional-quality results with fast speeds and wireless connectivity. It offers print, scan, copy, and fax capabilities, along with a sleek design and intuitive touchscreen control.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "HP DeskJet 3755 All-in-One Printer",
    price: "130.00",
    color: "Black",
    badge: false,
    des: "The HP DeskJet 3755 is a compact all-in-one printer that is perfect for small spaces. It provides high-quality prints, scans, and copies with wireless capabilities and an easy-to-use interface.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M428fdw",
    price: "400.00",
    color: "Black",
    badge: false,
    des: "The HP LaserJet Pro MFP M428fdw is a reliable monochrome printer designed for high-volume printing needs. It features fast speeds, strong security features, and a user-friendly touchscreen for efficient office work.",
  },
  {
    _id: 1014,
    img: newArrTwo,
    productName: "HP Envy 6455e All-in-One Printer",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "The HP Envy 6455e is a versatile all-in-one printer that offers excellent print quality and functionality. It supports printing, scanning, and copying, with seamless wireless connectivity and smart features.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "HP OfficeJet Pro 7740 Wide Format All-in-One Printer",
    price: "300.00",
    color: "Black",
    badge: false,
    des: "The HP OfficeJet Pro 7740 is a wide-format all-in-one printer that handles large documents and photos with ease. It offers versatile functionality including print, scan, copy, and fax, with robust performance and high-quality results.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "HP LaserJet Pro MFP M477fdw",
    price: "500.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M477fdw is a high-performance monochrome printer suitable for busy work environments. It offers fast printing speeds, comprehensive security features, and a large color touchscreen for ease of use.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "HP Tango X Smart Home Printer",
    price: "200.00",
    color: "Black",
    badge: false,
    des: "The HP Tango X is a sleek, smart home printer that integrates seamlessly with your home network. It offers high-quality printouts and supports wireless printing from your mobile devices.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "HP OfficeJet 250 All-in-One Portable Printer",
    price: "300.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet 250 is a portable all-in-one printer that combines print, scan, and copy functionalities in a compact, mobile-friendly design. Ideal for professionals on the go.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 offers high-volume printing with a low cost per page. It features easy-to-fill ink tanks and supports print, scan, copy, and mobile printing.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "HP OfficeJet Pro 9025e All-in-One Printer",
    price: "270.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 9025e is an advanced all-in-one printer with fast print speeds, high-quality output, and robust security features. It supports a range of office tasks with ease.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "HP DeskJet 4155 All-in-One Printer",
    price: "150.00",
    color: "Black",
    badge: true,
    des: "The HP DeskJet 4155 offers all-in-one functionality with easy wireless printing and a compact design. It is ideal for home and small office use with reliable performance.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "HP LaserJet Pro MFP M130fw",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "The HP LaserJet Pro MFP M130fw is a monochrome all-in-one printer offering fast speeds and a range of features including print, scan, copy, and fax. It’s designed for efficient office use.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "HP Color LaserJet Pro MFP M479fdw",
    price: "350.00",
    color: "Black",
    badge: true,
    des: "The HP Color LaserJet Pro MFP M479fdw delivers vibrant color prints with fast speeds and robust features. It includes print, scan, copy, and fax capabilities, perfect for busy offices.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "HP OfficeJet Pro 6978 All-in-One Printer",
    price: "340.00",
    color: "Black",
    badge: true,
    des: "The HP OfficeJet Pro 6978 is a feature-packed color inkjet photo printer designed to meet the demands of your home office or small workgroup. With its versatile functionality, including copy, scan, fax, wireless printing, two-sided duplex printing and scanning, and a color touchscreen, this printer delivers professional-quality results with exceptional convenience.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "HP Smart Tank 7301 All-in-One Printer",
    price: "320.00",
    color: "Black",
    badge: true,
    des: "The HP Smart Tank 7301 is designed for high-volume printing with easy-to-fill ink tanks. It supports wireless printing, scanning, and copying with exceptional efficiency.",
  },
];
// =================== PaginationItems End here =================
